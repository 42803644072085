<template>
  <div class="container">
    <headBar ref="headerHeight" title="包月私教卡特殊课包" left-arrow @click-left="newAppBack"></headBar>
    <div class="top-tip f24 row-start-center">
      <van-icon size="14" name="info-o" />&nbsp;<p>仅用户存在包月私教卡且状态为生效中可购买</p>
    </div>
    <div class="block">
      <div @click="goWeb" class="m-card col-between-start" :style="`background-image: url(${equityCardObj.backgroundImgUrl})`">
        <p class="title f44 fw6">{{ equityCardObj.cardName }}</p>
        <div v-if="equityCardObj.cardName" class="tags row-start-center">
          <div :class="['tag', 'row-center-center', 'f20', 'fw6', equityCardObj.status === 'ACTIVE' ? 'active' : '']">{{ equityCardObj.statusName }}</div>
          <div class="tag row-center-center f20 fw6">{{ equityCardObj.useValidityStr }}</div>
          <div class="tag row-center-center f20 fw6">{{ equityCardObj.usePlace }}</div>
          <div class="tag row-center-center f20 fw6">{{ equityCardObj.levelStr }}</div>
        </div>
      </div>
      <div class="select-box row-start-center flex-wrap">
        <div @click="switchSubfield(subfield)" :class="['select-item', 'row-center-center', 'f24', selectSubfield.id === subfield.id ? 'active' : '']" v-for="subfield in goodsList" :key="subfield.id">{{ subfield.subfieldName }}</div>
      </div>
      <div class="package-box">
        <template v-for="group in selectSubfield.groupInfos">
          <template v-for="goods in group.goodsInfos">
            <div :class="['package-item', 'row-between-center', selectItem.id === goods.id ? 'active' : '']" @click="switchItem(goods)" :key="goods.id">
              <div class="package-item-left">
                <div>
                  <p>{{ goods.goodsName }}</p><span v-if="cardStatus" class="tips">有效期：{{ coursePackageValidityStr }}</span>
                </div>
                <div>
                  <span>¥{{ goods.itemInfos[0].extendInfo.discountSinglePrice }}/节</span>
                  <span class="original-tips">¥{{ goods.itemInfos[0].extendInfo.originalSinglePrice }}/节</span>
                </div>
              </div>
              <div class="package-item-right">
                <p class="f32 fw6">¥{{ goods.salePrice }}</p>
                <p class="f22 p2">¥{{ goods.originPrice }}</p>
              </div>
            </div>
          </template>
        </template>
      </div>
      <div class="rule-box">
        <p class="title f32 fw6">购买须知</p>
        <p class="p1 f24">
          {{ selectSubfield.saleRule }}
        </p>
      </div>
    </div>

    <div class="bottom-btn">
      <div class="bottom-btn-shadow">
        <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/WeChat/package_presentation3.png">
      </div>
      <div class="bottom-btn-box row-between-center">
        <div class="price row-start-center f42 fw6">￥{{ selectItem.salePrice || 0 }} <div class="discount row-center-center f20 fw6">可省{{ selectItem.itemInfos[0].extendInfo.savePrice }}元</div>
        </div>
        <div :class="['btn', 'f32', 'fw6', 'row-center-center', goodsStatus === 'CAN_BUY' ? '' : 'disabled']" @click="subOrder">立即购买</div>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, appPay, appPaySuccess, appOpenWeb } from '@/lib/appMethod'
import headBar from '@/components/app/headBar'

const shopId = '10035'

export default {
  mixins: [userMixin],
  components: {
    headBar,
  },
  data() {
    return {
      equityCardObj: {}, // 权益卡对象

      goodsList: [], // 课包列表
      goodsStatus: '', // 购买按钮状态

      selectSubfield: {}, // 选中的分组
      selectItem: {}, // 选中的课包

      inviteTeacherId: '',
      inviteUserId: '',

      priceLevel: '', // 价格等级
      queryCityId: '', // 购买的城市id
    }
  },
  computed: {},
  async created() {
    await this.$getAllInfo(['userId', 'cityId'])
    initBack()

    await this.getEquityCard()
    this.getSpecialBag()

    // 若是教练邀请 获取教练id
    const q = this.$route.query
    this.inviteTeacherId = q.teacherId || q.inviteTeacherId
    this.inviteUserId = q.inviteUserId
    this.getBindInfo()

    // 价格 等级
    this.priceLevel = q.priceLevel
  },
  mounted() {
    document.addEventListener('visibilitychange', async () => {
      if (document.visibilityState === 'visible') {
        await this.getEquityCard()
        this.getSpecialBag()
      }
    })
  },
  methods: {
    newAppBack,
    getBindInfo() {
      this.$axios
        .post(this.baseURLApp + '/userDistribute/bindUser', {
          hasWx: true,
          activityNo: shopId, // shopId就是老接口里的activityNo
          userId: this.userId,
          saleTeacherId: this.inviteTeacherId,
          saleUserId: this.inviteUserId
        })
        .then((res) => {

        });
    },
    getEquityCard() {
      return this.$axios.post(this.baseURLApp + '/user/equityCard/equityCardPerCoursePackage', {
        token: this.token,
        userId: this.userId,
      }).then((res) => {
        const r = res.data
        const status = r.status
        // 无卡或者待激活时  只显示一张图
        if (!status || status === 'WAIT_ACTIVE') {
          this.equityCardObj = {
            backgroundImgUrl: r.img
          }
        } else {
          this.equityCardObj = r.equityCardUser
        }

        this.cardStatus = status
        this.coursePackageValidityStr = r.coursePackageValidityStr
        this.queryCityId = this.equityCardObj.cityIds ? this.equityCardObj.cityIds[0] : this.cityId
      })
    },
    goWeb() {
      if (!this.cardStatus) {
        const p = `${this.locationUrl}/#/superdeer-activity/sell-membership/coach?location=1`
        appOpenWeb('', p)
      }
      if (this.cardStatus === 'WAIT_ACTIVE') {
        const p = `${this.locationUrl}/#/month-card/my-card`
        appOpenWeb('', p)
      }
    },
    getSpecialBag() {
      this.$axios.post(this.baseURLApp + '/activityShopWeb/getShop', {
        cityId: this.queryCityId,
        shopId,
        userId: this.userId,
      }).then((res) => {
        this.goodsList = res.data.partitions.subfieldsInfos

        // 筛选 价格等级
        let filterArr = []
        if (this.priceLevel) {
          filterArr = this.goodsList.filter((item) => item.subfieldName.indexOf(this.priceLevel) > -1)
        }
        this.selectSubfield = filterArr.length > 0 ? filterArr[0] : this.goodsList[0]
        this.selectItem = filterArr.length > 0 ? filterArr[0].groupInfos[0].goodsInfos[0] : this.goodsList[0].groupInfos[0].goodsInfos[0]
        this.getBagStatus()
      })
    },
    getBagStatus() {
      const params = {
        goodsId: [this.selectItem.id],
        shopId,
        userId: this.userId,
      }
      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsStatus`, params).then(res => {
        this.goodsStatus = res.data.goodsStatusMap[this.selectItem.id]
      })
    },
    checkCanBuy() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios.post(this.baseURLApp + '/activityShopOrder/checkBuy', {
        goodsId: this.selectItem.id,
        goodsItemId: this.selectItem.itemInfos[0].id,
        shopId,
        userId: this.userId,
      }).then((r) => {
        this.$toast.clear()
        return r.data
      }).catch(() => {
        this.$toast.clear()
        return 0
      })
    },
    switchSubfield(subfield) {
      if (this.selectSubfield.id === subfield.id) return
      this.selectSubfield = subfield
      this.selectItem = subfield.groupInfos[0].goodsInfos[0]
      this.getBagStatus()
    },
    switchItem(item) {
      if (this.selectItem.id === item.id) return
      this.selectItem = item
      this.getBagStatus()
    },
    async subOrder() {
      const res = await this.checkCanBuy()
      if (!res) {
        this.$toast('无法购买')
        return
      }

      this.payAction()
    },
    payAction(info) {

      let url = `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/sell-membership/coach-specialbag&userId=1`
      let payData = {
        subject: this.selectItem.itemInfos[0].productName,
        extendsJson: {
          appVersion: '2.1.11',
          shopId,
          activityShopGoodsId: this.selectItem.id + '',
          activityShopGoodsItemId: this.selectItem.itemInfos[0].id,
          userCardId: this.equityCardObj.equityCardUserId
          // from: this.from,
          // userAddressId: this.address.userAddressId,
          // perUseVenueId: this.perUseVenueId
          // inviteUserId: this.inviteId,
        },
        totalAmount: this.selectItem.itemInfos[0].salePrice + '',
        venueId: '31',
        goodsNum: [12, 22].includes(this.selectItem.goodsType) ? '1' : undefined,
        isApp: '1',
        type: this.selectItem.goodsType, // 礼品卡12  课包10 实体卡22
        userId: this.userId,
        token: this.token,
        extendsType: '4',
        mouldId: this.selectItem.itemInfos[0].productId,
        whetherPrivacy: 0,
        originalPriceStr: '',
        title: this.appTypeStr === 'mini' ? '商品名称' : this.selectItem.itemInfos[0].productName,
      }
      if (this.appTypeStr === 'mini') {
        payData.wxPayType = 'applet'
      } else {
        appPaySuccess().then(() => {
          this.$toast('支付成功')
        })
      }

      // if (this.selectItem.canUseVoucher) {
      //   this.showPayPopup = true
      //   this.payData = payData
      //   this.payUrl = url
      // } else {
      //   appPay(payData, url);
      // }
      appPay(payData, url)
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  * {
    box-sizing: border-box;
  }

  .top-tip {
    width: 100%;
    height: 68px;
    padding: 0 32px;
    background: #F5F5F5;
  }

  .block {
    padding: 32px;
    min-height: 90vh;

    .m-card {
      width: 100%;
      height: 320px;
      border-radius: 14px;
      padding: 24px 28px 28px 28px;
      background-size: 100% 100%;
      color: #fff;
      .title {
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        -webkit-background-clip: text;
      }
      .tags {
        .tag {
          padding: 0 12px;
          height: 36px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 4px 4px 4px 4px;
          margin: 0 16px 0 0;

          &.active {
            color: #FFDE00;
          }
        }
      }
    }

    .select-box {
      margin: 24px 0 0;

      .select-item {
        margin: 16px 28px 0 0;
        padding: 0 28px;
        width: fit-content;
        min-width: 150px;
        height: 60px;
        background: #F6F6F6;
        color: #3C454E;
        border-radius: 64px;

        &.active {
          color: #fff;
          background: #828EF4;
        }
      }
    }

    .package-box {
      margin: 40px 0 0;

      .package-item {
        padding: 40px 40px 40px 30px;
        border: 1px solid #E6E6E6;
        border-radius: 8px;

        &.active {
          border: 1px solid #818FFE;
          background: rgba(129, 143, 254, 0.1);
        }
      }

      .package-item+.package-item {
        margin-top: 30px;
      }

      .package-item-left>div:first-child {
        font-weight: bold;
        font-size: 28px;
        margin-bottom: 32px;

        .tips {
          font-size: 24px;
          color: #6C727A;
          font-weight: normal;
          margin-top: 16px;
          display: inline-block;
        }
      }

      .package-item-left>div:first-child>text {
        color: #666666;
        font-size: 24px;
        font-weight: normal;
        margin-left: 20px;
      }

      .package-item-left>div:last-child {
        color: #F03C18;
        font-size: 24px;
        font-weight: bold;
        line-height: 24px;
        display: flex;
        align-items: flex-end;

        .original-tips {
          margin-left: 12px;
          line-height: 22px;
          font-size: 22px;
          color: #6C727A;
          font-weight: normal;
          text-decoration: line-through;
        }
      }

      .package-item-right {
        color: #F03C18;
        flex-shrink: 0;
        text-align: right;
      }

      .package-item-right .p2 {
        color: #6C727A;
        text-decoration: line-through;
      }
    }

    .rule-box {
      margin: 42px 0 0;

      .p1 {
        margin: 16px 0 0;
        line-height: 40px;
        white-space: pre-line;
      }
    }
  }

  .bottom-btn {
    width: 100vw;
    position: sticky;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 1000;
    box-sizing: border-box;

    .bottom-btn-shadow image {
      width: 100%;
      height: 12px;
      display: block;
    }

    .bottom-btn-box {
      background-color: white;
      padding: 20px 30px calc(20px + env(safe-area-inset-bottom));

      .price {
        color: #F03C18;

        .discount {
          padding: 0 10px;
          height: 36px;
          background: #F03C18;
          border-radius: 4px;
          margin: 0 0 0 16px;
          color: #fff;
        }
      }

      .btn {
        color: white;
        width: 232px;
        height: 80px;
        background-color: #818FFE;
        border-radius: 40px;

        &.disabled {
          background-color: #DDDDDD;
          color: #9AA1A9;
        }
      }
    }
  }





}</style>
